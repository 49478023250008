import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
} from 'react'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'
import {
  useParams,
} from 'react-router-dom'

import { custom_axios } from '../axios'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faSpinner,
} from '@fortawesome/free-solid-svg-icons'

// components
import { Post } from '../storybook/Post/Post'

// types
import {
  PostType,
} from '../types'

export function Home () {
  const { t } = useTranslation()
  const {
    user_id,
  } = useParams()

  // states
  const [loading, set_loading] = useState<boolean>(false)
  const [showing, set_showing] = useState<string>('post')

  const [posts, set_posts] = useState<PostType[]>([])
  const [has_more, set_has_more] = useState<boolean>(false)

  // trigger function that loads more posts when the user scroll to this element.
  const load_more_point_ref = useRef<HTMLDivElement>(null)

  const fetch_posts = (is_updating: boolean) => {
    type ResponseType = {
      data: {
        posts?: PostType[],
        has_more?: boolean,
      }
    }

    custom_axios
      .post(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/home/${user_id}`, {
        showing: showing,
        last_id: is_updating && posts.length ? posts[posts.length - 1]?.uuid : null,
      })
      .then((response: ResponseType) => {
        if (response.data?.posts) {
          set_has_more(response.data?.has_more ?? false)

          if (is_updating) {
            const new_posts = [...posts]
            set_posts(new_posts.concat(response.data?.posts))
          } else {
            set_posts(response.data?.posts)
          }

          set_loading(false)
        }
      })
  }

  useEffect(() => {
    const scroll_event = () => {
      if (!loading && load_more_point_ref.current?.offsetTop) {
        if (window.scrollY + ($(window).outerHeight() || 0) > load_more_point_ref.current?.offsetTop) {
          if (has_more) {
            fetch_posts(true)
            set_loading(true)
          }

          window.removeEventListener('scroll', scroll_event)
        }
      }
    }

    window.addEventListener('scroll', scroll_event)

    return () => {
      window.removeEventListener('scroll', scroll_event)
    }
  }, [posts])

  useEffect(() => {
    fetch_posts(false)
  }, [showing])

  const Posts = useCallback(() => posts.map((post, index) => {
    const update_record = (post: PostType) => {
      const new_posts = [...posts]
      new_posts[index] = post
      set_posts(new_posts)
    }

    const delete_record = () => {
      const new_posts = [...posts]
      new_posts.splice(index, 1)
      set_posts(new_posts)
    }

    return (
      <div
        key={index}
        ref={index === posts.length - 10 ? load_more_point_ref : null}
      >
        <Post
          post={post}
          update_record={ update_record }
          delete_record={ delete_record }
        />
      </div>
    )
  }), [posts])

  return (
    <section className='p-2.5 lg:p-5'>
      <div className="text-black mt-2 w-full flex flex-wrap flex-row justify-center items-center">
        <button
          onClick={ () => { set_showing('post') } }
          className={ `flex justify-center items-center px-3.5 py-2.5 grow w-fit h-full border border-solid shadow shadow-slate-200 cursor-pointer active:shadow-none active:translate-y-0.5 border-rose-700 hover:text-white hover:bg-rose-900 ${showing === 'post' ? 'bg-rose-900 text-white' : 'bg-white text-black'}` }
        >
          { t('Post') }
        </button>

        <button
          onClick={ () => { set_showing('good') } }
          className={ `flex justify-center items-center px-3.5 py-2.5 grow w-fit h-full border border-solid shadow shadow-slate-200 cursor-pointer active:shadow-none active:translate-y-0.5 border-sky-700 hover:text-white hover:bg-sky-900 ${showing === 'good' ? 'bg-sky-900 text-white' : 'bg-white text-black'}` }
        >
          { t('Good') }
        </button>

        <button
          onClick={ () => { set_showing('bad') } }
          className={ `flex justify-center items-center px-3.5 py-2.5 grow w-fit h-full border border-solid shadow shadow-slate-200 cursor-pointer active:shadow-none active:translate-y-0.5 border-emerald-700 hover:text-white hover:bg-emerald-900 ${showing === 'bad' ? 'bg-emerald-900 text-white' : 'bg-white text-black'}` }
        >
          { t('Bad') }
        </button>

        <button
          onClick={ () => { set_showing('comment') } }
          className={ `flex justify-center items-center px-3.5 py-2.5 grow w-fit h-full border border-solid shadow shadow-slate-200 cursor-pointer active:shadow-none active:translate-y-0.5 border-violet-700 hover:text-white hover:bg-violet-900 ${showing === 'comment' ? 'bg-violet-900 text-white' : 'bg-white text-black'}` }
        >
          { t('Comment') }
        </button>

      </div>

      <div className='mb-16'>
        <div className="w-full grid gap-x-3 gap-y-6 lg:grid-cols-3 2xl:grid-cols-4 mt-5">
          <Posts />
        </div>

        <div className={`${loading ? 'display' : 'hidden'} mt-10`}>
          <FontAwesomeIcon
            icon={faSpinner}
            className='fa-spin block w-14 h-14 mx-auto' />
        </div>
      </div>
    </section>
  )
}
