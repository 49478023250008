
import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import { Item } from '../../storybook/Aside/Item/Item';

// redux
import { useAppSelector } from '../../app/hooks'

interface Props {
  is_mobile?: boolean,
}

export const Functions = ({
  is_mobile = false,
}: Props) => {
  const { t, i18n } = useTranslation()

  // redux
  const user = useAppSelector(state => state.user.user)

  const apps = [
    {
      'name': t('Home'),
      'path': (
        user
        ? `/${i18n.language}/home/${user.id}`
        : `/${i18n.language}/login`
      ),
    },
    {
      'name': t('Post'),
      'path': (
        user
        ? `/${i18n.language}/post`
        : `/${i18n.language}/login`
      ),
    },
    {
      'name': t('Search'),
      'path': `/${i18n.language}`,
    },
  ]

  return apps.map((app, index) => {
    return (
      <Item
        key={ index }
        name={ app['name'] }
        path={ app['path'] }
        theme='rose'
        is_mobile={ is_mobile }
      />
    )
  })
}