import React, {
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
// import { Trans, useTranslation } from 'react-i18next'
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
// import { Link } from 'react-router-dom'

import { get_theme } from '../preferences'

import { custom_axios } from '../axios'

// redux
import { useAppSelector } from '../app/hooks'

// fontawesome
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import {
//   faUser,
//   faClock,
// } from '@fortawesome/free-solid-svg-icons'

// components
import { Slideshow } from '../storybook/Image/Slideshow/Slideshow'
import { PostMenu } from '../storybook/PostMenu/PostMenu'
import { Content as YoutubeContent } from '../storybook/Youtube/Content/Content'
import { Textarea } from '../storybook/Textarea/Textarea'
import { Normal as ButtonNormal } from '../storybook/Button/Normal/Normal'

import { Show as MaterialShow } from '../storybook/Material/Show/Show'
import { Show as StepShow } from '../storybook/Step/Show/Show'

// types
import {
  PostType,
  CommentType,
} from '../types'

interface Props {
  post?: PostType,
}

export function Show(props: Props) {
  const { t, i18n } = useTranslation()
  const {
    id,
  } = useParams()
  const navigate = useNavigate()

  // states
  const [post, set_post] = useState<PostType | null>(props?.post ?? null)
  const [comments, set_comments] = useState<CommentType[]>([])
  const [theme, set_theme] = useState<string>('light')

  // modules
  const utc_to_local = (datetime: string) => {
    const datetime_utc = new Date(datetime);

    let response_data = datetime_utc.getFullYear() + "/"
    response_data += (datetime_utc.getMonth() + 1).toString().padStart(2, '0') + "/"
    response_data += datetime_utc.getDate().toString().padStart(2, '0') + "-"
    response_data += datetime_utc.getHours().toString().padStart(2, '0') + ":"
    response_data += datetime_utc.getMinutes().toString().padStart(2, '0')

    return response_data
  }

  // redux
  const user = useAppSelector(state => state.user.user)

  useEffect(() => {
    (async() => {
      const new_theme = await get_theme()
      set_theme(new_theme ?? 'light')
    })()

    type ResponseType = {
      data: {
        post?: PostType,
        comments?: CommentType[],
      }
    }

    custom_axios
      .get(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/show/${id}`, {})
      .then((response: ResponseType) => {
        if (response.data?.post) {
          set_post(response.data?.post)
          set_comments(response.data?.comments??[])
        } else {
          set_post(null)
        }
      })
  }, [])

  if (post) {
    const update_record = (post: PostType) => {
      set_post(post)
    }

    const delete_record = () => {
      navigate(`/${i18n.language}`)
    }

    const comment_submit = async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault()
      const form_data = new FormData(e.currentTarget)

      if (user === null) {
        navigate(`/${i18n.language}/login`)
        return
      }

      type ResponseType = {
        data: {
          comments?: CommentType[],
        }
      }

      await custom_axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/comment`,
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response: ResponseType) => {
          if (response.data.comments) {
            set_comments(response.data.comments)
          } else {
            alert(t('Failed'))
          }
        })
        .catch(() => {
          alert(t('Failed'))
        })
    }

    const Comments = () => {
      return comments.map((comment, index) => {
        return (
          <div className="p-1 mt-1" key={index}>
            <div className="flex justify-start items-center">
              <Slideshow
                classes="block rounded-full border border-solid border-silver cursor-pointer w-14 h-14"
                images={ [{
                  'src': `${process.env.REACT_APP_BACKEND_HOST}${comment.user.icon}`,
                  'alt': 'Icon',
                }] }
              />
              <div className="mx-2">{ comment.user.username }</div>
              <div>{ utc_to_local(comment.created_at) }</div>
            </div>
            <div className="mt-1.5 mb-2 ml-2">{ comment.description }</div>
          </div>
        )
      })
    }

    const tags = post.tags.map((tag, tag_index) => {
      const tag_clicked = () => {
        navigate(`/${i18n.language}?tag=${tag.name}`)
      }
      return (
        <button
          key={tag_index}
          onClick={ tag_clicked }
          className="block px-1.5 py-1 bg-emerald-700 rounded border border-black cursor-pointer text-white hover:bg-emerald-900">
          { tag.name }
        </button>
      )
    })

    return (
      <section className='p-2.5 lg:p-5'>
        <div>{utc_to_local(post.created_at)}</div>

        <div>{post.user.username}</div>

        <PostMenu
          post={post}
          show_icon={true}
          update_record={update_record}
          delete_record={delete_record}
          icon_color={theme === 'dark' ? 'text-white' : 'text-black'}
        />

        <div className="flex flex-row items-center flex-wrap mt-3 mb-2.5 gap-x-2 gap-y-1">
          <div className=''>{ t('Tags') }:</div>
          { tags }
        </div>

        <h2 className="text-green-800 dark:text-green-400 border-t-0 border-r-8 border-l-8 border-b border-green-800 dark:border-green-400 border-solid text-center p-1.5 mt-3 mb-10">
          {post.title}
        </h2>

        {post.youtube && post.youtube !== '' && (
          <YoutubeContent
            width={post.youtube?.split(" ")?.[0]?.split("=")?.[1] ?? ''}
            height={post.youtube?.split(" ")?.[1]?.split("=")?.[1] ?? ''}
            src={post.youtube?.split(" ")?.[2]?.split("=")?.[1] ?? ''}
            classes='block w-full lg:w-8/12 mx-auto mt-5 border border-solid border-black'
          />
        )}

        {post.top_images?.length > 0 && (
          <Slideshow
            classes="block w-full lg:w-8/12 mx-auto mt-5 border border-solid border-silver cursor-pointer"
            images={post.top_images.map(image => {
              return {
                'src': `${process.env.REACT_APP_BACKEND_HOST}${image['file']}`,
                'alt': '',
              }
            })}
          />
        )}

        <div className="flex justify-start items-center mt-7">
          <span className="text-pink-800 dark:text-pink-400">{t('Amount')}:</span>&nbsp;{post.amount}&nbsp;
          <span className="text-pink-800 dark:text-pink-400 ml-3">{t('Time')}:</span>&nbsp;{post.time}
        </div>

        <h3 className="text-rose-800 dark:text-rose-400 text-center mb-5 mx-auto border-0 border-b border-solid border-rose-800 dark:border-rose-400 mt-8">
          {t('Material')}
        </h3>

        <MaterialShow materials={post.materials} />

        <h3 className="text-rose-800 dark:text-rose-400 text-center mb-5 mx-auto border-0 border-b border-solid border-rose-800 dark:border-rose-400 mt-8">
          {t('Step')}
        </h3>

        <StepShow steps={post.hows} />

        <h3 className="text-rose-800 dark:text-rose-400 text-center mb-5 mx-auto border-0 border-b border-solid border-rose-800 dark:border-rose-400 mt-8">
          {t('Point')}
        </h3>

        <p className="mt-4 mb-10">
          {post.point}l
        </p>

        <h2 id="comment" className="text-sky-800 dark:text-sky-400 border-t-0 border-r-8 border-l-8 border-b border-sky-800 dark:border-sky-400 border-solid text-center p-1.5 mt-3 mb-10">
          {t('Comment')}
        </h2>

        <form
          className="text-black"
          onSubmit={comment_submit}
        >
          <input type='hidden' name='id' value={id} />
          <Textarea
            name='description'
            required={true}
            classes='w-full block border border-black p-2.5 my-4.5 mx-auto border-solid bg-white mb-4'
            placeholder={t('Comment')}
            description={''}
          />

          <ButtonNormal
            type='submit'
            theme='sky'
            content={t('Submit')}
            classes='mt-1 mb-4 mx-auto'
          />
        </form>
        <Comments />
      </section >
    )
  } else {
    return (<>{t('This content does not exists')}</>)
  }
}
