import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'

export default function BottomMenu () {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  return (
    <>
      <button
        onClick={ () => { navigate(`/${i18n.language}/reset_password`) } }
        className="block cursor-pointer text-rose-900 dark:text-rose-300 mx-auto mt-6 text-ceter w-fit border-0 border-b border-dashed border-rose-900 dark:border-rose-300 px-2 pb-1 hover:text-rose-950 dark:hover:text-rose-500 hover:border-b-rose-950 dark:hover:border-b-rose-500"
      >
        { t('Reset password') }
      </button>

      <button
        onClick={ () => { navigate(`/${i18n.language}/login`) } }
        className="block cursor-pointer text-rose-900 dark:text-rose-300 mx-auto mt-4 text-ceter w-fit border-0 border-b border-dashed border-rose-900 dark:border-rose-300 px-2 pb-1 hover:text-rose-950 dark:hover:text-rose-500 hover:border-b-rose-950 dark:hover:border-b-rose-500"
      >
        { t('Login') }
      </button>
    </>
  )
}
