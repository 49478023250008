
import React from 'react'
import { useTranslation } from 'react-i18next'

import {
  MaterialType,
} from '../../../types'

export interface TagType {
  id: number,
  name: string,
}

interface PostProps {
  materials: MaterialType[],
}

export const Show = ({
  materials,
}: PostProps) => {
  const { t } = useTranslation()

  const Materials = () => {
    return materials.map((material, index) => {
      if (material.material === '') {
        return (
          <tr key={index}>
            <td
              className='border border-indigo-900 border-solid p-0.5 text-center bg-slate-100 text-indigo-800'
              colSpan={2}
            >
              {material.amount}
            </td>
          </tr>
        )
      } else if (material.amount === '') {
        return (
          <tr key={index}>
            <td
              className='border border-indigo-900 border-solid p-0.5 text-center bg-slate-100 text-rose-800'
              colSpan={2}
            >
              {material.material}
            </td>
          </tr>
        )
      }
      return (
        <tr key={index}>
          <td className='border border-indigo-900 border-solid p-0.5 text-center bg-slate-100'>{material.material}</td>
          <td className='border border-indigo-900 border-solid p-0.5 text-center bg-slate-100'>{material.amount}</td>
        </tr>
      )
    })
  }

  return (
    <table className="mt-2 mb-5 mx-auto w-full border-collapse overflow-x-auto text-black">
      <thead>
        <tr>
          <th className="border border-indigo-900 border-solid p-3 text-center text-indigo-900 bg-slate-300">
            {t('Material')}
          </th>
          <th className="border border-indigo-900 border-solid p-3 text-center text-indigo-900 bg-slate-300">
            {t('Amount')}
          </th>
        </tr>
      </thead>
      <tbody>
        <Materials />
      </tbody>
    </table>
  )
};
