import React from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
  useNavigate,
} from 'react-router-dom'

// components
import Menu from './Menu'
import { Normal as ButtonNormal } from '../../storybook/Button/Normal/Normal'

interface Props {
  email: string|undefined,
  authentication_code: string|undefined,
}

export default function NewPassword ({
  email,
  authentication_code,
}: Props) {

  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const form_data = new FormData(e.currentTarget);
    const form_value = Object.fromEntries(form_data)

    if (form_value['password'] === form_value['password_confirmation']) {
      type ResponseType = {
        data: {
          successed?: boolean,
        }
      }

      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/reset_password/new_password`,
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: ResponseType) => {
        if (response.data.successed) {
          alert( t('Reset password successed') )
          navigate(`/${i18n.language}`)
        } else {
          alert( t('Error. Please try again.') )
        }
      })
      .catch(() => {
        alert( t('Error. Please try again.') )
      })
    } else {
      alert( t("Password doesn't match") )
    }
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <form
        onSubmit={ submit }
        method="post"
      >
        {email && (
          <input type='hidden' name='email' value={email} />
        )}

        {authentication_code && (
          <input type='hidden' name='authentication_code' value={authentication_code} />
        )}

        <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-5">
          { t('New password') }
        </h2>
        <input
          type='password'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='password' />

        <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mt-6 mb-4">
          { t('New password confirmation') }
        </h2>
        <input
          type='password'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='password_confirmation' />

        <ButtonNormal
          type='submit'
          content={ t('Submit') }
          classes='mx-auto mt-7'
        />
      </form>

      <Menu />
    </section>
  )
}
