
import React from 'react'
import { useTranslation } from 'react-i18next'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faArrowUp,
  faArrowDown,
  faPlus
} from '@fortawesome/free-solid-svg-icons'

import {
  MaterialType,
} from '../../../types'

export interface TagType {
  id: number,
  name: string,
}

interface PostProps {
  materials: MaterialType[],
  set_materials: (materials: MaterialType[]) => void,
  deleted_materials: number[],
  set_deleted_materials: (deleted_materials: number[]) => void,
}

export const Post = ({
  materials,
  set_materials,
  deleted_materials,
  set_deleted_materials,
}: PostProps) => {
  const { t } = useTranslation()

  const Materials = () => {
    return materials.map((material, index) => {
      const material_changed = (e: React.ChangeEvent<HTMLInputElement>) => {
        const new_ = [...materials]
        new_[index]['material'] = e.target.value
        set_materials(new_)
      }

      const amount_changed = (e: React.ChangeEvent<HTMLInputElement>) => {
        const new_ = [...materials]
        new_[index]['amount'] = e.target.value
        set_materials(new_)
      }

      const plus_clicked = () => {
        const new_ = [...materials]
        new_.splice(
          index + 1,
          0,
          {
            material: '',
            amount: '',
          },
        )
        set_materials(new_)
      }

      const up_clicked = () => {
        const new_ = [...materials]
        const temp = new_[index]
        new_[index] = new_[index - 1]
        new_[index - 1] = temp
        set_materials(new_)
      }

      const down_clicked = () => {
        const new_ = [...materials]
        const temp = new_[index]
        new_[index] = new_[index + 1]
        new_[index + 1] = temp
        set_materials(new_)
      }

      const trash_clicked = () => {
        if ('id' in material) {
          const new_ = [...deleted_materials]
          new_.push(material?.id ?? 0)
          set_deleted_materials(new_)
        }
        const new_ = [...materials]
        new_.splice(index, 1)
        set_materials(new_)
      }

      return (
        <tr key={index}>
          <td className='border border-indigo-900 border-solid p-0.5 text-center bg-slate-100'>
            <input
              type='text'
              defaultValue={material.material}
              onBlur={material_changed}
              className='block bg-white w-full p-2.5 mx-auto text-center'
              placeholder={t('Material')}
            />
          </td>

          <td className='border border-indigo-900 border-solid p-0.5 text-center bg-slate-100'>
            <input
              type='text'
              defaultValue={material.amount}
              onBlur={amount_changed}
              className='block bg-white w-full p-2.5 mx-auto text-center'
              placeholder={t('Amount')}
            />
          </td>

          <td className='max-w-fit border border-indigo-900 border-solid p-0.5 text-center bg-slate-100'>
            <div className='w-fit flex flex-row justify-start items-center ml-2'>
              {index > 0 && (
                <FontAwesomeIcon
                  onClick={() => { trash_clicked() }}
                  icon={faTrash}
                  className={`w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600`}
                />
              )}

              <FontAwesomeIcon
                onClick={() => { plus_clicked() }}
                icon={faPlus}
                className={`w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600`}
              />

              {index > 0 && (
                <FontAwesomeIcon
                  onClick={() => { up_clicked() }}
                  icon={faArrowUp}
                  className={`w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600`}
                />
              )}

              {index < materials.length - 1 && (
                <FontAwesomeIcon
                  onClick={() => { down_clicked() }}
                  icon={faArrowDown}
                  className={`w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600`}
                />
              )}
            </div>
          </td>
        </tr>
      )
    })
  }

  return (
    <table className="mt-2 mb-5 mx-auto w-full border-collapse overflow-x-auto text-black">
      <thead>
        <tr>
          <th className="border border-indigo-900 border-solid p-3 text-center text-indigo-900 bg-slate-300">
            {t('Material')}
          </th>
          <th className="border border-indigo-900 border-solid p-3 text-center text-indigo-900 bg-slate-300">
            {t('Amount')}
          </th>
          <th className="border border-indigo-900 border-solid p-3 text-center text-indigo-900 bg-slate-300"></th>
        </tr>
      </thead>
      <tbody>
        <Materials />
      </tbody>
    </table>
  )
};
