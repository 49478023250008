import React, {
  useState,
} from 'react'

// components
import Authentication from './Authentication'
import Validate from './Validate'
import Register from './Register'

export function Index () {
  // states
  const [showing, set_showing] = useState<string>('authentication')
  const [email, set_email] = useState<string|undefined>()
  const [authentication_code, set_authentication_code] = useState<string|undefined>()
  const [social_account_provider, set_social_account_provider] = useState<string|undefined>()
  const [social_account_access_token, set_social_account_access_token] = useState<string|undefined>()

  switch (showing) {
    case 'validate':
      if (email) {
        return (
          <Validate
            email={ email }
            set_showing={ set_showing }
            set_authentication_code={ set_authentication_code }
          />
        )
      } else {
        return (<></>)
      }
    case 'register':
      return (
        <Register
          email={ email }
          authentication_code={ authentication_code }
          social_account_provider={ social_account_provider }
          social_account_access_token={ social_account_access_token }
        />
      )
    default:
      return (
        <Authentication
          set_showing={ set_showing }
          set_email={ set_email }
          set_social_account_provider={ set_social_account_provider }
          set_social_account_access_token={ set_social_account_access_token }
        />
      )
  }
}
