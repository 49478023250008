import React, {
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import {
  useNavigate,
} from 'react-router-dom'

// components
import Menu from './Menu'
import { Normal as ButtonNormal } from '../../storybook/Button/Normal/Normal'

interface Props {
  email: string|undefined,
  authentication_code: string|undefined,
  social_account_provider: string|undefined,
  social_account_access_token: string|undefined,
}

export default function Register ({
  email,
  authentication_code,
  social_account_provider,
  social_account_access_token,
}: Props) {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  interface ValidationErrorsType {
    id?: string,
  }

  // states
  const [validation_errors, set_validation_errors] = useState<ValidationErrorsType>({})

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const form_data = new FormData(e.currentTarget);
    const form_value = Object.fromEntries(form_data)

    type ResponseType = {
      data: {
        validation_errors?: ValidationErrorsType,
      }
    }

    if (form_value['password'] === form_value['password_confirmation']) {
      await axios({
        method: "post",
        url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/sign_up/register`,
        data: form_data,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((response: ResponseType) => {
        if (response.data?.validation_errors) {
          set_validation_errors(response.data?.validation_errors)
        } else {
          alert( t('Sign up successed. Please login.') )
          navigate(`/${i18n.language}/login`)
        }
      })
      .catch(() => {
        alert( t('Error. Please try again.') )
      })
    } else {
      alert( t("Password doesn't match") )
    }
  }

  return (
    <>
      <section className='p-2.5 lg:p-5'>
        <form
          onSubmit={ submit }
          method="post"
        >
          {email && (
            <input type='hidden' name='email' value={email} />
          )}

          {authentication_code && (
            <input type='hidden' name='code' value={authentication_code} />
          )}

          {social_account_provider && (
            <input type='hidden' name='provider' value={social_account_provider} />
          )}

          {social_account_access_token && (
            <input type='hidden' name='access_token' value={social_account_access_token} />
          )}

          <p className={`text-rose-900 dark:text-rose-300 mb-1 ${'id' in validation_errors ? 'block' : 'hidden'}`}>
            { validation_errors?.id }
          </p>

          <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-5">
            ID
          </h2>
          <input
            type='text'
            required
            className="block bg-white text-center p-2 border border-black border-solid text-black w-full mb-6"
            name='id' />

          <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-4">
            { t('Username') }
          </h2>
          <input
            type='text'
            required
            className="block bg-white text-center p-2 border border-black border-solid text-black w-full mb-6"
            name='username' />

          <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-5">
            { t('Password') }
          </h2>
          <input
            type='password'
            required
            className="block bg-white text-center p-2 border border-black border-solid text-black w-full mb-6"
            name='password' />

          <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-4">
            { t('Password confirmation') }
          </h2>
          <input
            type='password'
            required
            className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
            name='password_confirmation' />

          <ButtonNormal
            type='submit'
            content={ t('Submit') }
            classes='mx-auto mt-7'
          />
        </form>

        <Menu />
      </section>
    </>
  )
}
