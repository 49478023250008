import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import axios from 'axios'
import { Preferences } from '@capacitor/preferences'

// types
import {
  UserType,
} from '../types'

// @capacitor/preferences
const remove_user = async () => {
  await Preferences.remove({key: 'user'})
  return false
}

export interface UserStateType {
  user: UserType|null,
  status: string,
}

const initialState: UserStateType = {
  user: null,
  status: 'idle',
}

export const update_user_async = createAsyncThunk(
  'user/update',
  async (json_token: string) => {
    const token = `Bearer ${JSON.parse(json_token)['access']}`

    type ResponseType = {
      data?: UserType,
    }

    return await axios({
      method: 'get',
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/user`,
      data: {},
      headers: {
        'Authorization': token,
      },
    })
    .then(async (response: ResponseType) => {
      await Preferences.set({
        key: 'user',
        value: json_token,
      })

      return response.data
    }).catch(async () => {
      await remove_user()
      return false
    })
  }
)

interface update_with_social_account_props {
  social_account_provider: string,
  social_account_access_token: string,
}

export const update_with_social_account = createAsyncThunk(
  'user/update_with_social_account',
  async ({ social_account_provider, social_account_access_token }: update_with_social_account_props) => {
    type ResponseType = {
      data: {
        user: UserType,
        refresh: string,
        access: string,
      }
    }

    return await axios({
      method: 'post',
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/get_social_account`,
      data: {
        'provider': social_account_provider,
        'access_token': social_account_access_token,
      },
      headers: {},
    })
    .then(async (response: ResponseType) => {
      if (response.data) {
        const token = {
          'refresh': response.data.refresh,
          'access': response.data.access,
        }

        await Preferences.set({
          key: 'user',
          value: JSON.stringify(token),
        })
      } else {
        remove_user()
      }

      return response.data.user
    }).catch(async () => {
      remove_user()

      return false
    })
  }
)


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    clear_user: (state) => {
      state.user = initialState.user
      remove_user()
    },
    update_user: (state, action) => {
      state.user = action.payload
    }
  },
  extraReducers: (builder) => {
    builder
      // update
      .addCase(update_user_async.pending, (state) => {
        state.user = initialState.user
        state.status = 'loading'
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .addCase(update_user_async.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.user = action.payload
        } else {
          state.user = initialState.user
          remove_user()
        }
        state.status = 'idle'
      })
      .addCase(update_user_async.rejected, (state) => {
        state.user = initialState.user
        state.status = 'failed'
        remove_user()
      })

      // update_with_social_account
      .addCase(update_with_social_account.pending, (state) => {
        state.user = initialState.user
        state.status = 'loading'
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .addCase(update_with_social_account.fulfilled, (state, action: any) => {
        if (action.payload) {
          state.user = action.payload
        } else {
          state.user = initialState.user
          remove_user()
        }
        state.status = 'idle'
      })
      .addCase(update_with_social_account.rejected, (state) => {
        state.user = initialState.user
        state.status = 'failed'
        remove_user()
      })
  },
})

export const {
  clear_user,
  update_user,
} = userSlice.actions

export default userSlice.reducer
