
import React from 'react'
import $ from 'jquery'
import { useTranslation } from 'react-i18next'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faImage } from '@fortawesome/free-solid-svg-icons'

  interface imageType {
    id: number | undefined,
    file: File,
    src: string | undefined,
  }


interface UploadProps {
  title?: string,
  update?: (data: imageType) => void,
  classes?: string,
  name?: string,
  src?: string,
}

export const Upload = ({
  title,
  update,
  classes,
  name = '',
  src,
}: UploadProps) => {
  const { t } = useTranslation()

  const changed = (e: React.ChangeEvent<HTMLInputElement>) => {
    const reader = new FileReader();
    reader.onload = function () {
      const datas = {
        'id': undefined,
        'src': this.result ? this.result.toString() : '',
        'file': $<HTMLInputElement>(e.target)[0].files?.[0] ?? new File([''], ''),
      }
      if (update) {
        update(datas)
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    reader.readAsDataURL($<HTMLInputElement>(e.target)[0].files![0]);
  }

  const Title = () => {
    if (title) {
      return (
        <h2 className="text-sky-900 dark:text-sky-300 block text-center mt-5 mb-2">{ title }</h2>
      )
    }
  }

  return (
    <section className="w-full h-fit">
      <Title />

      <div className="relative flex flex-col justify-center items-center w-full h-full bg-slate-50 border-2 border-dashed border-blue-800">
        <input
          type='file'
          accept='image/*'
          name={ name }
          className={ `peer opacity-0 cursor-pointer absolute w-full h-full top-0 left-0 z-20 ${ classes }` }
          onChange={ changed }
        />

        <div
          className={ `flex-col justify-center items-center w-full h-full py-4 peer-hover:bg-slate-700 peer-hover:text-white text-center ${ src ? 'hidden' : 'flex' }` }>
          <FontAwesomeIcon
            icon={ faImage }
            className="w-12 h-12 mx-auto my-2"
          />
          <div className='px-2.5 py-0.5'>{ t('Image upload hint') }</div>
        </div>

        <div className={ `p-2 ${ src ? 'block' : 'hidden' }` }>
          <img
            src={ src }
            alt="Product images"
            className={`block max-w-full w-fit max-h-full mx-auto border border-black border-solid`}
          />
        </div>
      </div>
    </section>
  );
}