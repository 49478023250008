
import React, {
  // useState,
} from 'react'
import { useTranslation } from 'react-i18next'

// components
import { Content as YoutubeContent } from '../Content/Content'

export interface TagType {
  id: number,
  name: string,
}

interface Props {
  src: string,
  height: string,
  width: string,
  update_data: (description: string) => void,
}

// modules
const youtube_embed_code_disassemble = (code: string) => {
  if (code === "") { return "" }

  try {
    const content = code.match(/<iframe (.+)>.+/)?.[1]??''
    const width = content.match(/width="([^"]*)"/)?.[1]??''
    const height = content.match(/height="([^"]*)"/)?.[1]
    const src = content.match(/src="([^"]*)"/)?.[1]
    return "data-width=" + width + " data-height=" + height + " src=" + src;
  } catch (err) {
    return ""
  }
}

export const Form = ({
  src,
  height,
  width,
  update_data,
}: Props) => {
  const { t } = useTranslation()

  const youtube_on_paste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    update_data(youtube_embed_code_disassemble(e.clipboardData.getData('text/plain')??''))
  }

  return (
    <>
      { src !== '' && height !== '' && width !== '' && (
        <div
          className='w-full [&>iframe]:w-full'
        >
          <YoutubeContent
            width={width}
            height={height}
            src={src}
          />
        </div>
      ) }

      { (src === '' || height === '' || width === '') && (
        <input
          type='text'
          onPaste={youtube_on_paste}
          onChange={() => { console.log('Prohibit input text') }}
          value=''
          className='block bg-white w-full py-2.5 px-3 border border-black border-solid'
          placeholder={t('Please paste embed code')}
        />
      ) }

      <input
        type='button'
        onClick={ () => { update_data('') } }
        value={t('Reset')}
        className='block px-3.5 py-2.5 mx-auto mt-3.5 mb-2 rounded cursor-pointer text-white bg-cyan-600 text-center shadow shadow-black hover:bg-cyan-600 active:shadow-none active:translate-y-0.5'
      />
    </>
  )
}
