
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useAppSelector
} from '../../app/hooks'
import {
  Link,
  useNavigate,
} from 'react-router-dom'
import { custom_axios } from '../../axios'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faGear,
  faThumbsUp,
  faThumbsDown,
  faTrash,
  faComment,
} from '@fortawesome/free-solid-svg-icons'

import {
  PostType,
} from '../../types'

interface PostMenuProps {
  post: PostType,
  show_icon: boolean,
  update_record: (post: PostType) => void,
  delete_record: () => void,
  icon_color: string,
}

export const PostMenu = ({
  post,
  show_icon,
  update_record,
  delete_record,
  icon_color,
}: PostMenuProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user.user)

  const Gear = () => {
    if (user?.id === post.user.id) {
      return (
        <>
          <FontAwesomeIcon
            icon={ faTrash }
            onClick={ () => { 
              const confirmed = window.confirm(t('Are you sure to delete this post?'))
              if (confirmed) {
                custom_axios
                  .get(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/delete/${post.uuid}`, {})
                  .then(() => {
                    delete_record()
                  })
              }
            } }
            className={`block w-9 h-9 cursor-pointer ${icon_color} hover:text-slate-700`} />
          <FontAwesomeIcon
            icon={ faGear }
            onClick={ () => { navigate(`/${i18n.language}/post?id=${post.uuid}`) } }
            className={`block w-9 h-9 cursor-pointer ${icon_color} hover:text-violet-900`} />
        </>
      )
    }
  }

  const good_onclick = () => {
    if (user) {
      custom_axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/good/${post.uuid}`, {})
        .then((response: { data: { post: PostType } } ) => {
          if (response.data?.post) {
            update_record(response.data.post)
          }
        })
    } else {
      navigate(`/${i18n.language}/login`)
    } 
  }

  const bad_onclick = () => {
    if (user) {
      custom_axios
        .get(`${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/bad/${post.uuid}`, {})
        .then((response: { data: { post: PostType } }) => {
          if (response.data?.post) {
            update_record(response.data.post)
          }
        })
    } else {
      navigate(`/${i18n.language}/login`)
    } 
  }

  return (
    <>
      <div className="flex flex-row items-center flex-wrap gap-x-1.5">
        <Link
          className={`${show_icon ? 'block' : 'hidden'} flex items-center`}
          to={`/${i18n.language}/home/${post.user.id}`}>
          <img
            className='rounded-full cursor-pointer w-12 h-12 border-solid border border-white'
            src={ `${process.env.REACT_APP_BACKEND_HOST}${post.user.icon}` } />
        </Link>

        <div className='flex flex-row items-center gap-x-1'>
          <button
            onClick={ good_onclick }
            className='block'>
            <FontAwesomeIcon
              icon={ faThumbsUp }
              className={`block w-9 h-9 cursor-pointer ${post.is_pressing_good ? 'text-rose-900' : icon_color} hover:text-rose-500`} />
          </button>
          <p className="">{ post.good_count }</p>
        </div>

        <div className='flex flex-row items-center gap-x-1'>
          <button
            onClick={ bad_onclick }
            className='block'>
            <FontAwesomeIcon
              icon={ faThumbsDown }
              className={`block w-9 h-9 cursor-pointer ${post.is_pressing_bad ? 'text-indigo-900' : icon_color} hover:text-indigo-500`} />
          </button>
          <p className="">{ post.bad_count }</p>
        </div>

        <Link
          to={ `/${i18n.language}/show/${post.uuid}#comment` }
          className='block'
        >
          <button
            type='button'
            className='block'
          >
            <FontAwesomeIcon
              icon={ faComment }
              className={`block w-9 h-9 cursor-pointer ${post.is_pressing_bad ? 'text-emerald-900' : icon_color} hover:text-emerald-500`} />
          </button>
        </Link>

        <Gear />
      </div>
    </>
  )
};
