import React, {
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'

// components
import Menu from './Menu'
import { Normal as ButtonNormal } from '../../storybook/Button/Normal/Normal'

interface Props {
  email: string,
  set_showing: (showing: string,) => void,
  set_authentication_code: (code: string,) => void,
}

export default function Validate ({
  email,
  set_showing,
  set_authentication_code,
}: Props) {
  const { t } = useTranslation()

  // states
  const [missed_count, set_missed_count] = useState(0)

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form_data = new FormData(e.currentTarget);
    const form_value = Object.fromEntries(form_data)

    type ResponseData = {
      data: {
        successed?: boolean,
      }
    }

    await axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/reset_password/authentication`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: ResponseData) => {
      if (response.data.successed) {
        set_showing('register')
        set_authentication_code(form_value['code'].toString())
      } else {
        if (missed_count > 2) {
          alert( t('You missed too many times.') )
          set_showing('authentication')
        } else {
          set_missed_count(missed_count + 1)
        }
      }
    })
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <h3 className={ `mb-4 text-rose-900 dark:text-rose-300 text-center ${ missed_count > 0 ? 'block' : 'hidden' }` }>
        { t('Miss count') }: { missed_count }
      </h3>

      <form
        onSubmit={ submit }
        method="post"
      >
        {email && (
          <input type='hidden' name='email' value={email} />
        )}

        <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-4">
          { t('Authentication code') }
        </h2>
        <input
          type='text'
          required
          className="block bg-white text-center p-2 border border-black border-solid text-black w-full"
          name='code' />

        <ButtonNormal
          type='submit'
          content={ t('Submit') }
          classes='mx-auto mt-7'
        />
      </form>

      <Menu />
    </section>
  )
}
