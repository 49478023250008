import { createSlice } from '@reduxjs/toolkit'

export interface PostStateType {
  form_is_visible: boolean,
}

const initialState: PostStateType = {
  form_is_visible: false,
}

export const postSlice = createSlice({
  name: 'post',
  initialState,
  reducers: {
    update_form_is_visible: (state, action) => {
      state.form_is_visible = action.payload
    },
  },
})

export const {
  update_form_is_visible,
} = postSlice.actions

export default postSlice.reducer
