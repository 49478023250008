import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

export function Tos () {
  const { t, i18n } = useTranslation()

  return (
    <section className='p-2.5 lg:p-5'>
      <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1">{ t('TOS') }</h2>
      <p className="mx-auto my-4">
        利用規約（以下、「本規約」と称します）は、ユーザーが本サービスのコンテンツにアクセスし使用する場合に適用されます。本サービスを利用する事によって、ユーザーは本規約に拘束される事に同意した事になります。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">本規約への同意</h3>
      <p className="mx-auto my-4">
        本サービスを利用できるのは本規約に同意した者に限ります。また、ユーザーが未成年者である場合は、親権者などの法定代理人の同意を得た上で本サービスをご利用下さい。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">プライバシーポリシー</h3>
      <p className="mx-auto my-4">
        <Link
          to={`/${i18n.language}/privacy_policy`}
          className="text-fuchsia-900 dark:text-fuchsia-300 hover:text-fuchsia-950 hover:text-fuchsia-500 cursor-pointer"
        >
          本サービスのプライバシーポリシー
        </Link>
        は、ユーザーから本サービスに提供された情報がどのように取り扱われるか説明しています。ユーザーは本サービスを利用する事によりこのプライバシーポリシーを理解し、同意した事になります。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">本サービス上のコンテンツ</h3>
      <p className="mx-auto my-4">
        ユーザーは適用される法令や規則への遵守を含め、本サービスの利用および自身が提供するコンテンツに対して責任を負います。全てのコンテンツは、そのコンテンツの作成者が単独で責任を負うものとし、本サービスは一切の責任を負いません。<br/>
        ユーザーは本サービスの利用により、不快、有害、不確定、欺瞞的、その他不適切な投稿に接する可能性がある事を理解しているものとします。<br/>
        本サービスはユーザーによって投稿されたコンテンツの保存義務を負いません。ユーザーによって投稿されたコンテンツのバックアップは、ご自身で行っていただきます。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">ユーザーの権利、およびコンテンツに対する権利の許諾</h3>
      <p className="mx-auto my-4">
        本サービスを介してユーザーが送信、投稿、表示するあらゆるコンテンツの所有権は創作したユーザーにあるものとします。<br/>
        本サービスにコンテンツを投稿する事により、ユーザーは本サービスに対して当該コンテンツを使用、コピー、複製、処理、改変、修正、公表、送信、表示するための、世界的かつ非独占的ライセンス、サブライセンスおよび譲渡可能な無償ライセンスを付与するものとします。このライセンスによってユーザーは本サービスに対し、ご自身が投稿したコンテンツが世界中で閲覧可能になる事を承諾する事になります。ユーザーが本サービスに付与するライセンスはユーザーが本サービスからコンテンツを削除した後も、商業的に合理的な期間に渡って存続します。また、ユーザーが消去したコンテンツのコピーを本サービスが保持する可能性があることをユーザーは理解し、同意するものとします。<br/>
        ユーザーは本サービスにおけるコンテンツの収益化に関する権利を本サービスに付与します。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">禁止事項</h3>
      <p className="mx-auto my-4">
        本サービス、または他社の著作権、商標権などの知的財産権を侵害する行為を禁止します。<br/>
        法律、規則に違反するコンテンツの投稿を禁止します。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">コンテンツの消去について</h3>
      <p className="mx-auto my-4">
        本サービスは不適切だと判断したコンテンツを、独自の裁量により消去できるものとします。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">アカウントの停止、消去について</h3>
      <p className="mx-auto my-4">
        本サービスは不適切だと判断したアカウントを、独自の裁量により停止、消去できるものとします。
      </p>
      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">本規約の変更</h3>
      <p className="mx-auto my-4">
        本サービスは、必要に応じて本規約を変更する事ができます。。その場合、メールアドレスを登録済みのユーザーに対してはメールで通知致します。
      </p>

      <h3 className="text-sky-900 dark:text-sky-300 text-center my-5">本サービスの変更</h3>
      <p className="mx-auto my-4">
        本サービスは、いつでも任意の理由で本サービスを変更、制限、中断、終了する事ができるものとします。
      </p>
    </section>
  )
}
