
import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import { Textarea } from '../../Textarea/Textarea'
import { Normal as ButtonNormal } from '../../Button/Normal/Normal'
import { Menu } from '../../Menu/Menu'
import { Upload } from '../../Image/Upload/Upload'

import {
  stepType,
  imageType,
} from '../../../features/Post'

export interface TagType {
  id: number,
  name: string,
}

interface PostProps {
  steps: stepType[],
  set_steps: (steps: stepType[]) => void,
  deleted_steps: number[],
  set_deleted_steps: (deleted_steps: number[]) => void,
  deleted_step_images: number[],
  set_deleted_step_images: (deleted_step_images: number[]) => void,
}

export const Post = ({
  steps,
  set_steps,
  deleted_steps,
  set_deleted_steps,
  deleted_step_images,
  set_deleted_step_images,
}: PostProps) => {
  const { t } = useTranslation()

  const Steps = () => {
    return steps.map((step, index) => {
      const description_changed = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        const new_ = [...steps]
        new_[index]['description'] = e.target.value
        set_steps(new_)
      }

      const add_image = () => {
        const new_ = [...steps]
        new_[index]['images'].push({ 'id': undefined, 'file': new File([''], ''), 'src': undefined })
        set_steps(new_)
      }

      const plus_clicked = () => {
        const new_ = [...steps]
        new_.splice(
          index + 1,
          0,
          {
            description: '',
            images: [],
          },
        )
        set_steps(new_)
      }

      const up_clicked = () => {
        const new_ = [...steps]
        const temp = new_[index]
        new_[index] = new_[index - 1]
        new_[index - 1] = temp
        set_steps(new_)
      }

      const down_clicked = () => {
        const new_ = [...steps]
        const temp = new_[index]
        new_[index] = new_[index + 1]
        new_[index + 1] = temp
        set_steps(new_)
      }

      const trash_clicked = () => {
        if ('id' in step) {
          const new_ = [...deleted_steps]
          new_.push(step?.id ?? 0)
          set_deleted_steps(new_)
        }
        const new_ = [...steps]
        new_.splice(index, 1)
        set_steps(new_)
      }

      const Images = () => {
        return step['images'].map((image, image_index) => {
          const plus_clicked = () => {
            const new_ = [...steps]
            new_[index]['images'].splice(
              image_index + 1,
              0,
              { 'id': undefined, 'file': new File([''], ''), 'src': undefined },
            )
            set_steps(new_)
          }

          const up_clicked = () => {
            const new_ = [...steps]
            const temp = new_[index]['images'][image_index]
            new_[index]['images'][image_index] = new_[index]['images'][image_index - 1]
            new_[index]['images'][image_index - 1] = temp
            set_steps(new_)
          }

          const down_clicked = () => {
            const new_ = [...steps]
            const temp = new_[index]['images'][image_index]
            new_[index]['images'][image_index] = new_[index]['images'][image_index + 1]
            new_[index]['images'][image_index + 1] = temp
            set_steps(new_)
          }

          const trash_clicked = () => {
            if ('id' in step) {
              const new_ = [...deleted_step_images]
              new_.push(image?.id ?? 0)
              set_deleted_steps(new_)
            }
            const new_ = [...steps]
            new_[index]['images'].splice(index, 1)
            set_steps(new_)
          }

          return (
            <React.Fragment key={`${index}_${image_index}`}>
              <Menu
                classes={'mb-2 mt-2'}
                plus_clicked={plus_clicked}
                up_clicked={up_clicked}
                down_clicked={down_clicked}
                trash_clicked={trash_clicked}
              />

              <Upload
                update={(datas: imageType) => {
                  const new_ = [...steps]

                  if (image.id !== undefined && new_[index]['images'][image_index]['id']) {
                    const deleted_step_images_new = [...deleted_step_images]
                    deleted_step_images_new.push(new_[index]['images'][image_index]['id'] ?? 0)
                    set_deleted_step_images(deleted_step_images_new)

                    new_[index]['id'] = undefined
                  }

                  new_[index]['images'][image_index]['file'] = datas['file']
                  new_[index]['images'][image_index]['src'] = datas['src']

                  set_steps(new_)
                }}
                src={image.src?.startsWith('/media/') ? `${process.env.REACT_APP_BACKEND_HOST}${image.src}` : image.src}
              />
            </React.Fragment>
          )
        })
      }

      return (
        <div
          key={index}
        >
          <Menu
            classes={'mb-2 mt-2'}
            plus_clicked={plus_clicked}
            up_clicked={ index > 0 ? up_clicked : undefined }
            down_clicked={ index < steps.length - 1 ? down_clicked : undefined }
            trash_clicked={ index > 0 ? trash_clicked : undefined }
          />

          <div className='h-fit p-2 bg-white text-black border border-green-400'>
            <div className=''>
              <p className='border-b border-b-green-900 text-green-900 pb-0.5 text-center'></p>
              <div className='flex flex-row justify-start items-center ml-2'></div>
            </div>
            <p className='text-center'>
              <Textarea
                name='description'
                placeholder={t('Description')}
                required={false}
                on_change_action={description_changed}
                description={step.description}
                classes='mt-4.5 mb-4'
              />
            </p>

            <Images />

            <ButtonNormal
              type='button'
              theme='sky'
              content={t('Add')}
              on_click_action={add_image}
              classes='mt-1 mb-4 mx-auto'
            />
          </div>
        </div>
      )
    })
  }

  return (
    <div className='w-full grid gap-x-3 gap-y-6 xl:grid-cols-3'>
      <Steps />
    </div>
  )
};
