import React, {
  useState,
} from 'react'

// components
import Authentication from './Authentication'
import Validate from './Validate'
import NewPassword from './NewPassword'

export function Index () {
  // states
  const [showing, set_showing] = useState<string>('authentication')
  const [email, set_email] = useState<string|undefined>()
  const [authentication_code, set_authentication_code] = useState<string|undefined>()

  switch (showing) {
    case 'validate':
      if (email) {
        return (
          <Validate
            email={ email }
            set_showing={ set_showing }
            set_authentication_code={ set_authentication_code }
          />
        )
      } else {
        return (<></>)
      }
    case 'new_password':
      return (
        <NewPassword
          email={ email }
          authentication_code={ authentication_code }
        />
      )
    default:
      return (
        <Authentication
          set_showing={ set_showing }
          set_email={ set_email }
        />
      )
  }
}
