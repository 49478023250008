import { Preferences } from '@capacitor/preferences'

export const get_user = async () => {
  const { value } = await Preferences.get({ key: 'user' })
  return value
}

export const set_user = async (value: string) => {
  await Preferences.set({
    key: 'user',
    value: value,
  })
  return false
}

export const remove_user = async () => {
  await Preferences.remove({key: 'user'})
  return false
}

export const get_theme = async () => {
  const { value } = await Preferences.get({ key: 'theme' })
  return value
}

export const set_theme = async (value: string) => {
  await Preferences.set({
    key: 'theme',
    value: value,
  })
  return false
}

export const remove_theme = async () => {
  await Preferences.remove({key: 'theme'})
  return false
}
