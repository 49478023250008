
import React, {
  useRef,
  useEffect,
} from 'react'
import $ from 'jquery'

export interface TagType {
  id: number,
  name: string,
}

interface Props {
  src: string,
  height: string,
  width: string,
  classes?: string,
}

export const Content = ({
  src,
  width,
  height,
  classes,
}: Props) => {
  // refs
  const iframe_ref = useRef<HTMLIFrameElement>(null)

  useEffect(() => {
    if (iframe_ref.current && height && width) {
      const iframe_width = $(iframe_ref.current).innerWidth()
      const iframe_height = Math.round(iframe_width??0) * parseInt(height) / parseInt(width)
      $(iframe_ref.current).css('height', `${iframe_height}px`)
    }
  }, [])

  return (
    <iframe
      ref={iframe_ref}
      className={classes}
      src={src}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  )
}
