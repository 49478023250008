
import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'
// import {
//   useState,
// } from 'react'

import {
  PostType,
} from '../../types'

// components
import { Slideshow } from '../../storybook/Image/Slideshow/Slideshow'
import { PostMenu } from '../PostMenu/PostMenu'

export interface TagType {
  id: number,
  name: string,
}

interface PostProps {
  post: PostType,
  tag_onclick?: (name: string) => void,
  update_record: (post: PostType) => void,
  delete_record: () => void,
}

export const Post = ({
  post,
  tag_onclick,
  update_record,
  delete_record,
}: PostProps) => {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  
  // modules
  const utc_to_local = (datetime: string) => {
    const datetime_utc = new Date(datetime);

    let response_data = datetime_utc.getFullYear() + "/"
    response_data += (datetime_utc.getMonth() + 1).toString().padStart(2, '0') + "/"
    response_data += datetime_utc.getDate().toString().padStart(2, '0') + "-"
    response_data += datetime_utc.getHours().toString().padStart(2, '0') + ":"
    response_data += datetime_utc.getMinutes().toString().padStart(2, '0')

    return response_data
  }

  // const [form_showing, set_form_showing] = useState<boolean>(false)

  const tags = post.tags.map((tag, tag_index) => {
    const tag_clicked = () => {
      if(tag_onclick) {
        tag_onclick(tag.name)
      } else {
        navigate(`/${i18n.language}?tag=${tag.name}`)
      }
    }
    return (
      <button
        key={tag_index}
        onClick={ tag_clicked }
        className="block px-1.5 py-1 bg-emerald-700 rounded border border-black cursor-pointer text-white hover:bg-emerald-900">
        { tag.name }
      </button>
    )
  })

  return (
    <div
      className="block text-black p-3 rounded bg-white h-full"
    >
      <div>
        <p className="">{ utc_to_local(post.created_at) }</p>
        <p className="mb-1">{ post.user.username }</p>

        <PostMenu
          post={post}
          show_icon={true}
          update_record={ update_record }
          delete_record={ delete_record }
          icon_color='text-black'
        />

        <div className="flex flex-row items-center flex-wrap mt-1.5 mb-2.5 gap-x-2 gap-y-1">
          <div className=''>{ t('Tags') }:</div>
          { tags }
        </div>

        <Link
          to={ `/${i18n.language}/show/${post.uuid}` }
          className='block w-full border-0 border-b border-solid border-purple-800 hover:border-purple-900 pb-0.5 text-purple-800 hover:text-purple-900 hover:font-bold text-center'
        >
          { post.title }
        </Link>

        { post.top_images.length > 0 && (
          <Slideshow
            classes="cursor-pointer w-full block mt-1 mx-auto border border-solid border-black"
            images={ post.top_images.map(image => {
              return {
                'src': `${process.env.REACT_APP_BACKEND_HOST}${image['file']}`,
                'alt': '',
              }
            }) }
          />
        ) }
      </div>
    </div>
  )
};
