import React from 'react'
import { useTranslation } from 'react-i18next'
import axios from 'axios'
import { Preferences } from '@capacitor/preferences'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons'

// redux
import { useAppSelector, useAppDispatch } from '../app/hooks'

// aside slice
import {
  update_mobile_is_visible,
} from '../slice/asideSlice'

// user slice
import {
  clear_user,
} from '../slice/userSlice'

// components
import { Functions } from './Aside/Functions'
import { Languages } from './Aside/Languages'
import { Item } from '../storybook/Aside/Item/Item'

export const AsideMobile = () => {
  const { t, i18n } = useTranslation()

  // redux
  const dispatch = useAppDispatch()
  const mobile_is_visible = useAppSelector(state => state.aside.mobile_is_visible)
  const user = useAppSelector(state => state.user.user)

  const UserProfile = () => {
    if (user) {
      return (
        <div className='flex flex-col justify-center items-center'>
          <img
            className="block w-14 h-14 rounded-full border border-solid border-black cursor-pointer"
            src={`${process.env.REACT_APP_BACKEND_HOST}${user.icon}`}
            alt='Icon'
          />

          <div className='text-left mt-1.5 text-black'>@ { user.id }</div>
        </div>
      )
    }
  }

  const Login = () => {
    if (!user) {
      return (
        <Item
          name={ t('Login') }
          path={ `/${i18n.language}/login` }
          theme='lime'
          is_mobile={ true }
        />
      )
    } else {
      const clicked = async () => {
        const { value } = await Preferences.get({ key: 'user' })

        if (value) {
          axios({
            method: "post",
            url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/logout`,
            data: {
              'token': JSON.parse(value)['refresh'],
            },
            headers: {},
          })
        }

        dispatch(clear_user())
      }

      return (
        <Item
          name={ t('Logout') }
          theme='lime'
          action='customize'
          onclick_action={ clicked }
          is_mobile={ true }
        />
      )
    }
  }

  return (
    <aside
      className={`${ mobile_is_visible ? 'translate-x-0' : 'translate-x-full' } z-30 flex flex-col top-0 right-0 w-10/12 h-full fixed duration-100 bg-slate-200 rounded-tl rounded-bl text-center justify-start px-1.5 pb-2 pt-2 border-solid border border-blue-800`}
    >
      <div className='flex flex-row justify-between items-start mb-5'>
        <UserProfile />

        <FontAwesomeIcon
          icon={ faCircleXmark }
          className='cursor-pointer block w-12 h-12 bg-white rounded-full [&>path]:fill-sky-900 hover:[&>path]:fill-sky-900'
          onClick={ () => { dispatch(update_mobile_is_visible(false)) } }
        />
      </div>

      <div className="flex flex-col justify-start items-stretch">
        <Functions is_mobile={ true } />

        { user &&
          <Item
            name={ t('Setting') }
            theme='lime'
            path={`/${i18n.language}/setting`}
            is_mobile={ true }
          />
        }

        <Login />

        <Item
          name={ t('Change theme') }
          theme='lime'
          action='customize'
          onclick_action={ async () => {
            const { value } = await Preferences.get({ key: 'theme' })

            await Preferences.set({
              key: 'theme',
              value: value === 'dark' ? 'light' : 'dark',
            })

            window.location.reload()
          } }
          is_mobile={ true }
        />

        <div className='border-0 border-b border-solid border-black text-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>Langs</div>
        <Languages is_mobile={ true } />

        {/* <div className='border-0 border-b border-solid border-black text-black pb-1 mb-1 mt-2.5 text-center lg:border-b-white'>
          { t('Other sites') }
        </div> */}

        {/* <Item
          name={ t('Twitter download ranking') }
          path={ `https://x.hamary.co/${ i18n.language }/video_download_ranking/1` }
          theme='emerald'
          action='redirect_target_blank'
        /> */}
      </div>
    </aside>
  )
}

export default AsideMobile
