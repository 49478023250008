import React, {
  useState,
  useEffect,
} from 'react'
import { useTranslation } from 'react-i18next'
import { custom_axios } from '../axios'
import {
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

// components
import { Upload } from '../storybook/Image/Upload/Upload'
import { Form as YoutubeForm } from '../storybook/Youtube/Form/Form'
import { Menu } from '../storybook/Menu/Menu'
import { Textarea } from '../storybook/Textarea/Textarea'
import { Normal as ButtonNormal } from '../storybook/Button/Normal/Normal'

import { Post as MaterialPost } from '../storybook/Material/Post/Post'
import { Post as StepPost } from '../storybook/Step/Post/Post'

// types
import {
  PostType,
  TopImageType,
  MaterialType,
} from '../types'

export interface imageType {
  id: number | undefined,
  file: File,
  src: string | undefined,
}

export interface stepType {
  id?: number,
  description: string,
  images: imageType[],
}

export function Post() {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  // states
  const [title, set_title] = useState('')
  const [tags, set_tags] = useState('')
  const [description, set_description] = useState('')
  const [youtube, set_youtube] = useState('')
  const [time, set_time] = useState('')
  const [amount, set_amount] = useState('')
  const [point, set_point] = useState('')
  const [top_images, set_top_images] = useState<imageType[]>([])
  const [deleted_top_images, set_deleted_top_images] = useState<number[]>([])

  const [materials, set_materials] = useState<MaterialType[]>([
    {
      material: '',
      amount: '',
    },
  ])
  const [deleted_materials, set_deleted_materials] = useState<number[]>([])

  const [steps, set_steps] = useState<stepType[]>([{
    description: '',
    images: [],
  }])
  // const [step_images, set_step_images] = useState<StepImageType[]>([])
  const [deleted_steps, set_deleted_steps] = useState<number[]>([])
  const [deleted_step_images, set_deleted_step_images] = useState<number[]>([])

  useEffect(() => {
    if (searchParams.get('id')) {
      type ResponseType = {
        data: {
          post?: PostType,
        }
      }

      custom_axios({
        method: "get",
        url: `${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/get_post/${searchParams.get('id')}`,
        data: {},
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
        .then((response: ResponseType) => {
          if (response.data.post) {
            set_title(response.data.post.title)
            set_description(response.data.post.description)

            set_tags(response.data.post.tags.map(tag => tag.name)?.join(', '))

            set_time(response.data.post.time)
            set_amount(response.data.post.amount)
            set_point(response.data.post.point)
            set_youtube(response.data.post.youtube)

            set_materials(response.data.post.materials.map((material) => {
              return ({
                id: material.id,
                material: material.material,
                amount: material.amount,
              })
            }))

            set_steps(response.data.post.hows.map((how) => {
              const images_new: imageType[] = how.images?.map((image) => {
                return {
                  'id': image.id,
                  'file': new File([''], ''),
                  'src': `${process.env.REACT_APP_BACKEND_HOST}${image.file}`,
                }
              })??[]

              return ({
                id: how.id,
                description: how.description,
                images: images_new,
              })
            }))

            set_top_images(response.data.post.top_images.map((image: TopImageType) => {
              return {
                'id': image.id,
                'file': new File([''], ''),
                'src': `${process.env.REACT_APP_BACKEND_HOST}${image.file}`,
              }
            }))
          } else {
            alert(t('You cannot edit this post'))
          }
        })
        .catch(() => {
          alert(t('You cannot edit this post'))
        })
    }
  }, [])

  const submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    const form_data = new FormData(e.currentTarget)

    let top_images_count = 0
    top_images.map((image, index) => {
      if (image['file']['name'] !== '') {
        form_data.set(`top_image_file_${index}`, image['file'])
        top_images_count++
      } else if (image['src'] !== undefined && image['id'] !== undefined) {
        form_data.set(`top_image_id_${index}`, image['id'].toString())
        top_images_count++
      }
    })

    form_data.set('top_images_count', top_images_count.toString())

    form_data.set('materials', JSON.stringify(materials))

    // eslint-disable-next-line @typescript-eslint/no-explicit-any, prefer-const
    let steps_: any[] = [...steps]

    steps.map((step, index) => {
      let step_images_count = 0
      step['images'].map((image, image_index)=>{
        if (image['file']['name'] !== '') {
          form_data.set(`step_image_${index}_file_${image_index}`, image['file'])
          step_images_count++
        } else if (image['src'] !== undefined && image['id'] !== undefined) {
          form_data.set(`step_image_${index}_id_${image_index}`, image['id'].toString())
          step_images_count++
        }

        steps_[index]['images_count'] = step_images_count
      })
    })

    form_data.set('steps', JSON.stringify(steps_))

    deleted_top_images.map(image => {
      form_data.append('deleted_top_images', image.toString())
    })

    deleted_materials.map(deleted_material => {
      form_data.append('deleted_materials', deleted_material.toString())
    })

    deleted_steps.map(deleted_step => {
      form_data.append('deleted_steps', deleted_step.toString())
    })

    deleted_step_images.map(deleted_step_image => {
      form_data.append('deleted_step_images', deleted_step_image.toString())
    })

    type ResponseType = {
      data: {
        post?: PostType,
      }
    }

    await custom_axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/posts/api/post`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .then((response: ResponseType) => {
        if (response.data.post) {
          navigate(`/${i18n.language}/show/${response.data.post.uuid}`)
        } else {
          alert(t('Failed'))
        }
      })
      .catch(() => {
        alert(t('Failed'))
      })
  }

  const add_top_image = () => {
    const new_ = [...top_images]
    new_.push({ 'id': undefined, 'file': new File([''], ''), 'src': undefined })
    set_top_images(new_)
  }

  const TopImages = () => {
    return top_images.map((image, index) => {
      const plus_clicked = () => {
        const new_ = [...top_images]
        new_.splice(index + 1, 0, { 'id': undefined, 'file': new File([''], ''), 'src': undefined })
        set_top_images(new_)
      }

      const up_clicked = () => {
        const new_ = [...top_images]

        const temporary = new_[index]
        new_[index] = new_[index - 1]
        new_[index - 1] = temporary

        set_top_images(new_)
      }

      const down_clicked = () => {
        const new_ = [...top_images]

        const temporary = new_[index]
        new_[index] = new_[index + 1]
        new_[index + 1] = temporary

        set_top_images(new_)
      }

      const trash_clicked = () => {
        const new_ = [...top_images]

        if (new_[index]['id'] && new_[index]['id']) {
          const deleted_images_new = [...deleted_top_images]
          deleted_images_new.push(new_[index]['id'] ?? 0)
          set_deleted_top_images(deleted_images_new)
        }

        new_.splice(index, 1)

        set_top_images(new_)
      }

      return (
        <div
          key={index}
          className='mb-4'
        >
          <Menu
            classes={'mb-2 mt-2'}
            plus_clicked={plus_clicked}
            up_clicked={up_clicked}
            down_clicked={down_clicked}
            trash_clicked={trash_clicked}
          />

          <Upload
            update={(datas: imageType) => {
              const new_ = [...top_images]

              if (image.id !== undefined && new_[index]['id']) {
                const deleted_images_new = [...deleted_top_images]
                deleted_images_new.push(new_[index]['id'] ?? 0)
                set_deleted_top_images(deleted_images_new)

                new_[index]['id'] = undefined
              }

              new_[index]['file'] = datas['file']
              new_[index]['src'] = datas['src']

              set_top_images(new_)
            }}
            src={image.src?.startsWith('/media/') ? `${process.env.REACT_APP_BACKEND_HOST}${image.src}` : image.src}
          />
        </div>
      )
    })
  }

  const Youtube = () => {
    const [width, height, src] = [youtube?.split(" ")?.[0]?.split("=")?.[1] ?? '', youtube?.split(" ")?.[1]?.split("=")?.[1] ?? '', youtube?.split(" ")?.[2]?.split("=")?.[1] ?? '']

    const update_data = (data: string) => {
      set_youtube(data)
    }

    return (
      <>
        <input
          type='hidden'
          name='youtube'
          value={youtube}
        />

        <YoutubeForm
          update_data={update_data}
          src={src}
          height={height}
          width={width}
        />
      </>
    )
  }

  return (
    <section className='p-2.5 lg:p-5'>
      <form
        className="text-black"
        onSubmit={submit}
      >
        {searchParams.get('id') && (
          <input type='hidden' name='id' value={searchParams.get('id')??''} />
        )}

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='title'
        >
          {t('Title')}
        </label>
        <h2>
          <input
            required
            type='text'
            placeholder={t('Title')}
            className="w-full block border border-black p-2.5 my-4.5 mx-auto text-center border-solid bg-white mb-4"
            onBlur={(e) => set_title(e.target.value)}
            name='title'
            defaultValue={title} />
        </h2>

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='tags'
        >
          {t('Tags')}
        </label>
        <h2>
          <input
            required
            type='text'
            placeholder={t('Tags')}
            className="w-full block border border-black p-2.5 my-4.5 mx-auto text-center border-solid bg-white mb-4"
            onBlur={(e) => set_tags(e.target.value)}
            name='tags'
            defaultValue={tags}
          />
        </h2>

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='description'
        >
          {t('Description')}
        </label>
        <Textarea
          name='description'
          placeholder={t('Description')}
          required={true}
          on_change_action={(e: React.ChangeEvent<HTMLTextAreaElement>) => { set_description(e.target.value) }}
          description={description}
          classes='mt-4.5 mb-4'
        />

        <label className="block text-center pb-0.5 text-rose-900 dark:text-rose-300">{t('Youtube')}</label>
        <Youtube />

        <label className="block text-center pb-0.5 text-rose-900 dark:text-rose-300">{t('Top images')}</label>
        <TopImages />

        <ButtonNormal
          type='button'
          theme='sky'
          content={t('Add image')}
          on_click_action={add_top_image}
          classes='mt-1 mb-4 mx-auto'
        />

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='time'
        >
          {t('Time')}
        </label>
        <h2>
          <input
            required
            type='text'
            placeholder={t('Time')}
            className="w-full block border border-black p-2.5 my-4.5 mx-auto text-center border-solid bg-white mb-4"
            onBlur={(e) => set_time(e.target.value)}
            name='time'
            defaultValue={time} />
        </h2>

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='amount'
        >
          {t('Amount')}
        </label>
        <h2>
          <input
            required
            type='text'
            placeholder={t('Amount')}
            className="w-full block border border-black p-2.5 my-4.5 mx-auto text-center border-solid bg-white mb-4"
            onBlur={(e) => set_amount(e.target.value)}
            name='amount'
            defaultValue={amount}
          />
        </h2>

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='material'
        >
          {t('Material')}
        </label>
        <MaterialPost
          materials={materials}
          set_materials={(new_materials) => { set_materials(new_materials) }}
          deleted_materials={deleted_materials}
          set_deleted_materials={(new_deleted_materials) => { set_deleted_materials(new_deleted_materials) }}
        />

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='step'
        >
          {t('Step')}
        </label>
        <StepPost
          steps={steps}
          set_steps={(new_steps) => { set_steps(new_steps) }}
          deleted_steps={deleted_steps}
          set_deleted_steps={(new_deleted_steps) => { set_deleted_steps(new_deleted_steps) }}
          deleted_step_images={deleted_step_images}
          set_deleted_step_images={(new_deleted_step_images) => { set_deleted_step_images(new_deleted_step_images) }}
        />

        <label
          className="block text-center pb-0.5 text-rose-900 dark:text-rose-300"
          htmlFor='point'
        >
          {t('Point')}
        </label>

        <Textarea
          name='point'
          required={true}
          classes='w-full block border border-black p-2.5 my-4.5 mx-auto border-solid bg-white mb-4'
          placeholder={t('Point')}
          description={point}
          on_change_action={(e) => set_point(e.target.value)}
        />

        <ButtonNormal
          type='submit'
          theme='sky'
          content={t('Submit')}
          classes='mt-7 mb-7 mx-auto'
        />
      </form>
    </section>
  )
}
