import axios from 'axios'
import { Preferences } from '@capacitor/preferences'

const custom_axios = axios.create()

const set_token = async () => {
  const { value } = await Preferences.get({ key: 'user' })

  if (value) {
    const token = `Bearer ${JSON.parse(value)['access']}`
    custom_axios.defaults.headers.common['Authorization'] = token
  }

  return
}

const set_axios = async () => {
  await set_token()

  custom_axios.interceptors.response.use(
    function (response) {
      return response
    }, function (error) {
      if (error.response?.status === 401) {
        const language = window.location.pathname?.split('/')?.[1]
        window.location.href = `/${language}/login`
      }
      console.log(error)
      return error
      // return Promise.reject(error)
    }
  )
}

set_axios()

export { custom_axios }