import React, {
  useRef,
  useEffect,
} from 'react'

interface Props {
  name?: string,
  placeholder?: string,
  required: boolean,
  on_change_action?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void,
  description?: string,
  classes?: string,
}

export const Textarea = ({
  name,
  placeholder,
  required = false,
  on_change_action,
  description,
  classes,
}: Props) => {
  // refs
  const textarea_ref = useRef<HTMLTextAreaElement|null>(null)

  // resize size of textarea
  useEffect(() => {
    if (textarea_ref.current) {
      textarea_ref.current.style.height = 'auto'
      textarea_ref.current.style.height = textarea_ref.current.scrollHeight + 'px'
    }
  }, [])

  // resize size of textarea
  const textarea_resize_height = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    e.currentTarget.style.height = 'auto'
    e.currentTarget.style.height = e.currentTarget.scrollHeight + 'px'
  }

  if (!on_change_action) {
    return (
      <textarea
        ref={ textarea_ref }
        required={ required }
        placeholder={ placeholder }
        name={ name }
        onChange={ textarea_resize_height }
        defaultValue={ description }
        className={`block w-full h-fit border-solid border border-black bg-white p-3 ${classes}`}
      >
      </textarea>
    )
  } else {
    return (
      <textarea
        ref={ textarea_ref }
        required={ required }
        placeholder={ placeholder }
        name={ name }
        onChange={ (e) => {
          textarea_resize_height(e)
        } }
        onBlur={ (e) => {
          on_change_action(e)
        } }
        defaultValue={ description }
        className={`block w-full h-fit border-solid border border-black bg-white p-3 ${classes}`}
      >
      </textarea>
    )
  }
}
