
import React from 'react'

// fontawesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTrash,
  faArrowUp,
  faArrowDown,
  faPlus
} from '@fortawesome/free-solid-svg-icons'

interface Props {
  classes?: string,
  plus_clicked?: () => void,
  up_clicked?: () => void,
  down_clicked?: () => void,
  trash_clicked?: () => void,
}

export const Menu = ({
  classes,
  plus_clicked,
  up_clicked,
  down_clicked,
  trash_clicked,
}: Props) => {
  return (
    <section className={ `flex flex-row items-center ${classes}` }>
      { trash_clicked !== undefined && (
        <FontAwesomeIcon
          onClick={ () => { trash_clicked() } }
          icon={faTrash}
          className={ `w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600` }
        />
      )}

      { plus_clicked !== undefined && (
        <FontAwesomeIcon
          onClick={ () => { plus_clicked() } }
          icon={faPlus}
          className={ `w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600` }
        />
      )}

      { up_clicked !== undefined && (
        <FontAwesomeIcon
          onClick={ () => { up_clicked() } }
          icon={faArrowUp}
          className={ `w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600` }
        />
      )}

      { down_clicked !== undefined && (
        <FontAwesomeIcon
          onClick={ () => { down_clicked() } }
          icon={faArrowDown}
          className={ `w-10 h-10 block cursor-pointer ml-2 [&>path]:fill-black dark:[&>path]:fill-white [&>path]:hover:fill-gray-600 dark:hover:[&>path]:hover:fill-gray-600` }
        />
      )}
    </section>
  )
}
