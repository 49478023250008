
import React from 'react'
import { useTranslation } from 'react-i18next'

// components
import { Slideshow } from '../../Image/Slideshow/Slideshow'

import {
  StepType,
} from '../../../types'

export interface TagType {
  id: number,
  name: string,
}

interface PostProps {
  steps: StepType[],
}

export const Show = ({
  steps,
}: PostProps) => {
  const { t } = useTranslation()

  const Steps = () => {
    return steps.map((step, index) => {
      return (
        <div
          key={index}
        >
          <div className='h-fit p-2 bg-white text-black border border-green-800 dark:border-green-400 border-solid'>
            <div className=''>
              <p className='border-0 border-b border-b-green-900 border-solid mb-1 text-green-900 pb-0.5 text-center'>{ t('Step') }{index+1}</p>
              <div className='flex flex-row justify-start items-center ml-2'></div>
            </div>
            <p className='text-center'>{step.description}</p>

            { step.images?.length > 0 && (
              <Slideshow
                classes="block w-full my-2 border border-solid border-silver cursor-pointer"
                images={ step.images.map(image => {
                  return {
                    'src': `${process.env.REACT_APP_BACKEND_HOST}${image['file']}`,
                    'alt': '',
                  }
                }) }
              />
            ) }
          </div>
        </div>
      )
    })
  }

  return (
    <div className='w-full grid gap-x-3 gap-y-6 xl:grid-cols-3'>
      <Steps />
    </div>
  )
};
