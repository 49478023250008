
import React from 'react'
import { useNavigate } from 'react-router-dom'

// redux
import {
  useAppDispatch,
  useAppSelector,
} from '../../../app/hooks'

// aside slice
import {
  update_mobile_is_visible,
} from '../../../slice/asideSlice'

interface Props {
  name: string,
  path?: string,
  theme: 'rose' | 'lime' | 'sky' | 'emerald' | 'violet',
  action?: 'navigate' | 'redirect_target_blank' | 'customize',
  onclick_action?: () => void,
  is_mobile?: boolean,
}

export const Item = ({
  name,
  path,
  theme,
  action = 'navigate',
  onclick_action,
  is_mobile = false,
}: Props) => {
  const navigate = useNavigate()

  // redux
  const dispatch = useAppDispatch()
  const mobile_is_visible = useAppSelector(state => state.aside.mobile_is_visible)

  let color = ''
  switch (theme) {
    case 'rose':
      color = 'text-rose-900 lg:text-rose-900 lg:dark:text-rose-300 hover:bg-rose-900'
      break
    case 'lime':
      color = 'text-lime-900 lg:text-lime-900 lg:dark:text-lime-300 hover:bg-lime-900'
      break
    case 'sky':
      color = 'text-sky-900 lg:text-sky-900 lg:dark:text-sky-300 hover:bg-sky-900'
      break
    case 'emerald':
      color = 'text-emerald-900 lg:text-emerald-900 lg:dark:text-emerald-300 hover:bg-emerald-900'
      break
    case 'violet':
      color = 'text-violet-900 lg:text-violet-900 lg:dark:text-violet-300 hover:bg-violet-900'
      break
  }

  const btn_style = `cursor-pointer p-1.5 rounded duration-500 ${color} hover:text-white dark:hover:text-white`

  switch (action) {
    case 'navigate':
      return (
        <button
          onClick={ () => {
            navigate(path ?? '')
            if (is_mobile && mobile_is_visible) {
              dispatch(update_mobile_is_visible(false))
            }
          } }
          className={ btn_style }
        >
          { name }
        </button>
      )
    case 'redirect_target_blank':
      return (
        <a
          href={ path || '' }
          target='_blank'
          className={ btn_style }
        >
          { name }
        </a>
      )
    case 'customize':
      return (
        <button
          onClick={ () => {
            if (onclick_action) {
              onclick_action()
            }
            if (is_mobile && mobile_is_visible) {
              dispatch(update_mobile_is_visible(false))
            }
          } }
          className={ btn_style }
        >
          { name }
        </button>
      )
    default:
      return (
        <div className={ btn_style }>{ name }</div>
      )
  }
};
