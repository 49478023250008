import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  useNavigate,
} from 'react-router-dom'

import {
  custom_axios,
} from '../../axios'

// redux
import { useAppSelector, useAppDispatch } from '../../app/hooks'

// components
import { Normal as ButtonNormal } from '../../storybook/Button/Normal/Normal'

// user slice
import {
  update_user,
} from '../../slice/userSlice'

// types
import {
  UserType,
} from '../../types'

export function Username () {
  const { t, i18n } = useTranslation()
  const navigate = useNavigate()

  // redux
  const dispatch = useAppDispatch()
  const user = useAppSelector(state => state.user.user)

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const form_data = new FormData(e.currentTarget)

    type ResponseType = {
      data: {
        user?: UserType,
      }
    }

    custom_axios({
      method: "post",
      url: `${process.env.REACT_APP_BACKEND_HOST}/en/users/api/setting/username`,
      data: form_data,
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((response: ResponseType) => {
      if (response.data.user) {
        dispatch(update_user(response.data.user))
        navigate(`/${i18n.language}`)
      } else {
        alert( t('Error') )
      }
    })
    .catch(() => {
      alert( t('Error') )
    })
  }

  if (user) {
    return (
      <section className='p-2.5 lg:p-5'>
        <form
          onSubmit={ submit }
          method="post"
        >

          <h2 className="text-rose-900 dark:text-rose-300 border-0 border-b border-dashed border-rose-900 dark:border-rose-300 text-center pb-1 mb-4">
            { t('Username') }
          </h2>

          <input
            type='text'
            required
            defaultValue={ user?.username }
            className="block text-center p-2 bg-white border border-black border-solid text-black w-full"
            name='username' />

          <ButtonNormal
            type='submit'
            content={ t('Submit') }
            classes='mx-auto mt-7'
          />
        </form>
      </section>
    )
  } else {
    return (
      <h2 className='text-center text-rose-900 dark:text-rose-300 mt-4'>{ t('Please login first') }</h2>
    )
  }
}
